import {
  Box,
  List,
  ListItemText,
  Divider,
  Typography,
  ListItemAvatar,
  Avatar,
  ListItemButton,
} from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"

const PlanPage = () => {
  const plansDthroughG = [
    {
      letter: "D",
      color: "#a8323a",
      title: "AMBULANCE CHASING BLUMBERG",
      subtitle:
        "Advancing Meaningful Budget Utilization, Limiting Abuse, Nurturing Civic Engagement: Challenging Harmful Actions, Supporting Integrity, Nurturing Governance. Balancing Leadership Under Moral Boundaries, Establishing Responsible Government.",
    },
    {
      letter: "E",
      color: "#32a852",
      title: "KIM DUMB AS A STONE",
      subtitle:
        "Keeping Integrity Mandatory: Dismantling Unfair Municipal Bureaucracy, Addressing Self-serving Agendas, Strengthening Trust Over Neglectful Executions.",
    },
    {
      letter: "F",
      color: "#a2a832",
      title: "BLANK-STARE BARISA",
      subtitle:
        "Better Leadership Advancing Neighborhood Knowledge: Safeguarding Taxpayer Assets, Redirecting Efforts. Building Accountable Responsibility In Service Action.",
    },
    {
      letter: "G",
      color: "#9c5509",
      title: "NEGLIGENT NEUKIRCH",
      subtitle:
        "Necessary Ethics and Governance Leadership, Inspiring Greater Engagement, Negating Toxicity: Nurturing Equitable Urban Knowledge, Improving Responsibility and Community Hope.",
    },
  ]
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "2rem",
        flexDirection: "column",
        gap: "2rem",
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>Plan Page</title>
      </Helmet>
      <Typography
        textAlign={"center"}
        fontSize={24}
        fontWeight={600}
        width={{ xs: "100%", sm: "70%" }}
      >
        Comprehensive revenue plans providing objective solutions to enhance
        Highland Park's fiscal resilience.
      </Typography>
      <List
        sx={{
          width: { xs: "100%", sm: "80%", md: "50%" },
          bgcolor: "background.paper",
          border: "2px solid black",
          borderRadius: ".25rem",
        }}
      >
        {/* First List Item */}
        <ListItemButton
          component={Link} // Make ListItemButton a link
          to="/planA"
          sx={{
            textDecoration: "none",
            color: "inherit",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)", // Add hover effect
            },
            "&:focus": {
              backgroundColor: "rgba(0, 0, 0, 0.12)", // Add focus effect
            },
          }}
        >
          <ListItemAvatar>
            <Avatar alt="EREP Plan" sx={{ backgroundColor: "#84A586" }}>
              A
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography fontWeight={600} fontSize={"20px"}>
                The "Equitable Revenue Enhancement Plan"
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: "text.primary", display: "inline" }}
                >
                  A Tiered Tax Structure for Large-Scale Entities in Southeast
                  Highland Park
                </Typography>
              </React.Fragment>
            }
          />
        </ListItemButton>
        <Divider variant="inset" component="li" />

        {/* Second List Item */}
        <ListItemButton
          component={Link} // Make ListItemButton a link
          to="/planB"
          sx={{
            textDecoration: "none",
            color: "inherit",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)", // Add hover effect
            },
            "&:focus": {
              backgroundColor: "rgba(0, 0, 0, 0.12)", // Add focus effect
            },
          }}
        >
          <ListItemAvatar>
            <Avatar alt="BEACON Plan" sx={{ backgroundColor: "#806201" }}>
              B
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography fontWeight={600} fontSize={"20px"}>
                The "BEACON" Plan
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: "text.primary", display: "inline" }}
                >
                  Budget Enhancement And Community Optimization Network
                </Typography>
              </React.Fragment>
            }
          />
        </ListItemButton>
        {/* List item 3 */}
        <ListItemButton
          component={Link} // Make ListItemButton a link
          to="/planC"
          sx={{
            textDecoration: "none",
            color: "inherit",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)", // Add hover effect
            },
            "&:focus": {
              backgroundColor: "rgba(0, 0, 0, 0.12)", // Add focus effect
            },
          }}
        >
          <ListItemAvatar>
            <Avatar alt="RISE Plan" sx={{ backgroundColor: "#2f3fcc" }}>
              C
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography fontWeight={600} fontSize={"20px"}>
                The "RISE" Plan
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: "text.primary", display: "inline" }}
                >
                  Revenue Initiatives for Sustainable Equity
                </Typography>
              </React.Fragment>
            }
          />
        </ListItemButton>
        {plansDthroughG.map((planLetter) => (
          <ListItemButton
            sx={{
              textDecoration: "none",
              color: "inherit",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.08)", // Add hover effect
              },
              "&:focus": {
                backgroundColor: "rgba(0, 0, 0, 0.12)", // Add focus effect
              },
            }}
          >
            <ListItemAvatar>
              <Avatar
                alt="RISE Plan"
                sx={{ backgroundColor: planLetter.color }}
              >
                {planLetter.letter}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography fontWeight={600} fontSize={"20px"}>
                  {planLetter.title}
                </Typography>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: "text.primary", display: "inline" }}
                  >
                    {planLetter.subtitle}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItemButton>
        ))}
        <ListItemButton
          component={Link} // Make ListItemButton a link
          to="/planH"
          sx={{
            textDecoration: "none",
            color: "inherit",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)", // Add hover effect
            },
            "&:focus": {
              backgroundColor: "rgba(0, 0, 0, 0.12)", // Add focus effect
            },
          }}
        >
          <ListItemAvatar>
            <Avatar alt="RISE Plan" sx={{ backgroundColor: "#7532a8" }}>
              H
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography fontWeight={600} fontSize={"20px"}>
                NANCY SUCKS
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: "text.primary", display: "inline" }}
                >
                  New Accountability and Necessary Change Yielding Sustainable
                  Urban Community Key Solutions
                </Typography>
              </React.Fragment>
            }
          />
        </ListItemButton>
      </List>
    </Box>
  )
}

export default PlanPage
